import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import OutlineButton from "app/components/Buttons/OutlineButton";
import React, { useEffect } from "react";
import Header from "./components/Header";
import TableHeader from "./components/TableHeader";
import QuestionTile from "./components/QuestionTile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingQuestions,
  selectQuestions,
  selectQuestionsPagination,
  selectSelectedQuestions,
} from "app/containers/Stations/redux/selector";
import { actions } from "app/containers/Stations/redux/slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import TableLoader from "app/components/TableLoader";
import { selectOrganization } from "app/redux/selectors";

export default function AllQuestions({ disabled }: { disabled?: boolean }) {
  const openAddQuestionPageInNewTab = () => {
    window.open("/app/questions/new?mode=add", "_blank");
  };
  const { t } = useTranslation();
  const questions = useSelector(selectQuestions);
  const selectedQuestions = useSelector(selectSelectedQuestions);
  const isLoading = useSelector(selectIsLoadingQuestions);
  const pagination = useSelector(selectQuestionsPagination);
  const dispatch = useDispatch();
  const searchParams  = useSearchParams();
  const hasMoreItems = pagination.page < pagination.total;
  const user = searchParams?.[0]?.get("user");
  const type = searchParams?.[0]?.get("type");
  const q = searchParams?.[0]?.get("q");
  const organization = useSelector(selectOrganization);

  const resetFilters = () => {
    dispatch(
      actions.getQuestions({
        page: 1,
        perPage: pagination.perPage,
        organization: organization ? organization.value : undefined,
        user:  '',
        type:  '',
        search:  '',
      })
    );
  }
  useEffect(() => {
    dispatch(
      actions.getQuestions({
        page: 1,
        perPage: pagination.perPage,
        organization: organization ? organization.value : undefined,
        user: user || '',
        type: type || '',
        search: q || ''
      })
    );
  }, [ pagination.perPage, dispatch, user,q, type,organization]);

  const memoizedQuestions = React.useMemo(() => {
    return questions.filter((q) => !selectedQuestions.includes(q._id));
  }, [selectedQuestions, questions]);
  
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getQuestions({
        page: pagination.page + 1,
        perPage: pagination.perPage,
        organization: organization ? organization.value : undefined,
        user: user || '',
        type: type || '',
        search: q || ''
      }));
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "100%",
            color: "#5E6781",
          }}
        >
          {t("STATIONS.FORM.ALL_QUESTIONS")}
        </Typography>
        <OutlineButton
          label={t("QUESTIONS.QUESTION")}
          startIcon={<Add />}
          sx={{
            border: "1px solid #E24399",
            color: "#E24399",
            textTransform: "uppercase",
            "&:hover": {
              color: "#E24399",
              border: "1px solid #E24399",
            },
          }}
          disabled={disabled}
          onClick={openAddQuestionPageInNewTab}
        />
      </Box>
      <Grid2 container spacing={2}>
        <Header disabled={disabled} resetFilter={resetFilters}/>
        <Grid2 xs={12}>
          <TableHeader />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              maxHeight: 1000,
              padding: "4px",
            }}
            id="scrollableDivSmall"
          >
            <InfiniteScroll
              dataLength={memoizedQuestions?.length ?? 0}
              loader={<TableLoader />}
              hasMore={hasMoreItems}
              next={getNextPage}
              style={{ flex: 1 }}
              height={1000}
              scrollableTarget="scrollableDivSmall"
            >
               <Box display={'flex'} flexDirection={'column'} flex={1}>
                {memoizedQuestions?.length > 0 ? (
                  memoizedQuestions.map((q, i) => (
                    <QuestionTile
                      key={i}
                      disabled={disabled}
                      question={q}
                      handleBackClick={() =>
                        dispatch(actions.addToSelectedQuestions(q._id))
                      }
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "100%",
                      color: "#5E6781",
                      
                      textAlign: "center",
                      my: "40px",
                    }}
                  >
                    {t("STATIONS.FORM.NO_QUESTIONS")}
                  </Typography>
                )}
              </Box>
            </InfiniteScroll>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}
