import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import StationQuestionTile from "../StationQuestionTile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectQuestions,
  selectSelectedQuestions,
} from "app/containers/Stations/redux/selector";
import { actions } from "app/containers/Stations/redux/slice";
import { Question } from "app/containers/Stations/types";

export default function StationQuestions({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();
  const questions = useSelector(selectQuestions);
  const selectedQuestions = useSelector(selectSelectedQuestions);
  const dispatch = useDispatch();

  const memoizedQuestions = questions?.filter((question) =>
    selectedQuestions?.some(
      (selectedQuestion) => selectedQuestion === question?._id
    )
  ) as Question[];
  const sortedQuestions = selectedQuestions
    ?.map((selectedQuestion) =>
      memoizedQuestions?.find((question) => question?._id === selectedQuestion)
    )
    ?.filter((s) => s) as Question[];

  return (
    <Box maxHeight={1200} overflow="auto">

        <Typography
          style={{
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "100%",
            color: "#5E6781",
            marginBottom: "24px",
          }}
        >
          {t("STATIONS.FORM.STATIONS_QUESTIONS")}
        </Typography>
 
      <Box
        sx={{
          width: "100%",
          height: "52px",
          padding: "14px 10px",
          borderRadius: "16px",
          angle: "-180 deg",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flex: 1
          }}
        > &nbsp;</Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flex: 2
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#3C3C43",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >{t("STATIONS.FORM.LABELS.QUESTION_ID")}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flex: 8
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#3C3C43",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {t("STATIONS.FORM.QUESTION_BODY")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flex: 1
          }}
        > &nbsp;</Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {sortedQuestions.length < 1 ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#5E6781",
              textAlign: "center",
              my: "40px",
            }}
          >
            {t("STATIONS.FORM.NO_QUESTIONS_ADDED_YET")}
          </Typography>
        ) : (
          sortedQuestions.map((question, i) => (
            <StationQuestionTile
              index={i}
              key={question._id}
              disabled={disabled}
              question={question}
              handleRemoveClick={() =>
                dispatch(actions.removeFromSelectedQuestions(question._id))
              }
            />
          ))
        )}
      </Box>
    </Box>
  );
}
