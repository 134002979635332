import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { ResultsState } from '../types';
import {orderBy} from 'lodash';
const ERROR_DELTA = 180;
const selectDomain = (state: RootState) => state.resultsState || ResultsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectList = createSelector([selectDomain], state => state.list);
export const selectOrderedList = createSelector([selectList], data => {
  // Algo
  // 1. sort at top who have ->  row.currentStation?._id
  // if draw then we will apply score. high score wins -> row.score || 0
  //  if draw in scrore (same score)
  //   a. TimeInSEC =>  row.startTime - row.endTime if  not row.endTime then row.endTime = now()
  //   b. ErrorBySec => (E+1)*180 (row.incorrectAttempts + 1) *  ERROR_DELTA
  //   c TimeInSEC * ErrorBySec 
  //   d. Low will be at top 
  return orderBy(data, [
    (row) => !!row.assignedStations.length, // first sort by whether `assignedStations` exist
    (row) => row.score || 0, // if draw, sort by score (default to 0 if not defined)
    (row) => {
      const endTime = row.endTime ? new Date(row.endTime).getTime() : Date.now(); // if endTime is missing, use current time
      const startTime = new Date(row.startTime).getTime();
      return (endTime - startTime) / 1000; // Time in seconds
    },
    (row) => {
      return (row.incorrectAttempts + 1) * ERROR_DELTA; // Error factor
    },
    (row) => {
      const timeInSec = (new Date(row.endTime ? row.endTime : Date.now()).getTime() - new Date(row.startTime).getTime()) / 1000;
      const errorBySec = (row.incorrectAttempts + 1) * ERROR_DELTA;
      return timeInSec * errorBySec; // Final tiebreaker factor
    }
  ], ['desc', 'desc', 'asc', 'asc', 'asc']); // Sorting order
});
export const selectCurrentResultId = createSelector([selectDomain], state => state.currentResult);
export const selectGroupPhotos = createSelector([selectDomain], state => state.list.filter((x)=>x.allowedToPublish && x?.groupPhoto?.url));
export const selectTicket = createSelector([selectDomain], state => state.ticket);

export const selectPagination = createSelector([selectDomain], state => state.pagination);
export const selectResultInfo = createSelector([selectDomain], state => {
  return state.resultInfo.results.map(result => ({
    ...result,
    stationResults: result.stationResults.filter(stationResult => Object.keys(stationResult).length > 0)
  })).filter(result => result.stationResults.length > 0);
});
export const selectError = createSelector([selectDomain], state => state.error);
export const selectCurrentResult = createSelector([selectDomain], state => {
  const currentResult = state.list.find(f => f._id == state.currentResult)
  return currentResult
})
export const selectStationsInfo = createSelector([selectDomain], state => {
  const ticket = state.ticket
  const currentResult = state.list.find(f => f._id == state.currentResult)
  if(!currentResult) {return []}
  const currentStationIndex =  currentResult.assignedStations?.indexOf(currentResult.currentStation?._id)as number
  
  const res = (ticket?.game?.gameStations || []).map((s: any, index) => {
    const station = s.station
    const i =  currentResult.assignedStations?.indexOf(station._id) as number
    let status = 'IN_PROGRESS'
    if(currentResult.currentStation?._id) {
      if(currentStationIndex > -1 && i > -1) {
        if(currentStationIndex < i) {status =  'PENDING'} 
        if(currentStationIndex > i) {status =  'DONE'} 
      }
    }else {status =  'DONE'} 
    return {
      id: index + 1,
      name:   station.name,
      status,
    }
  })

  return res

});
