import { Box, IconButton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import SearchInput from "app/components/SearchInput";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function Header({ disabled, resetFilters }: { disabled?: boolean; resetFilters(): void; }) {
  const { t } = useTranslation();
  const navigate = useNavigate();



  const handleResetFilters = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("q");
    navigate(`?${params.toString()}`);
    resetFilters()
    
  }

  return (
    <>
      <Grid2 xs={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SearchInput
            disabled={disabled}
            provisionLabel
          />
        </Box>
      </Grid2>
      <Grid2 xs={1} display={'flex'}  justifyContent={'center'} alignItems={'center'}>
      <IconButton
          onClick={handleResetFilters}
        >
          <RefreshIcon />
        </IconButton>
      </Grid2>
    </>
  );
}
