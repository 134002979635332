import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Question, Station, StationsState } from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";
import { randomString } from "utils/helpers";

const initialState = StationsState;

export const formSlice = createSlice({
  name: "stationsState",
  initialState,
  reducers: {
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      if (action.payload.key !== "qrCode") {
        state.isUnsaved = true;
      }
    },
    setFormErrors(state, action: PayloadAction<FormError[]>) {
      action.payload.forEach(({ name, error }) => {
        set(state.form, `${name}.error`, error);
      });
    },
    getStations(state, action: PayloadAction<Query>) {
      state.isLoading = true;
    },
    getStationsSuccess(
      state,
      action: PayloadAction<{ stations: Station[]; pagination: Pagination }>
    ) {
      state.stations =
        action.payload.pagination.page === 1
          ? action.payload.stations
          : [...state.stations, ...action.payload.stations];
      state.stationsPagination = action.payload.pagination;
      state.isLoading = false;
    },
    getStationsError(state) {
      state.isLoading = false;
    },
    getStation(
      state,
      action: PayloadAction<{ id: string; duplicate?: boolean }>
    ) {
      state.isLoading = true;
    },
    getStationSuccess(state, action: PayloadAction<Station>) {
      state.form.name.value = action.payload.name;
      state.form.organizationId.value = action.payload.organization;
      state.form.goToMessage1.value = action.payload.goToMessage1;
      state.form.goToMessage2.value = action.payload.goToMessage2;
      state.form.feedBackOnSuccessfulArrival.value =
        action.payload.feedBackOnSuccessfulArrival;
      state.form.stationInstruction.value = action.payload.stationInstruction;
      state.form.type.value = action.payload.location?.type;
      state.form.address.value = action.payload.location?.address;
      state.form.code.value = action.payload.location?.code;
      state.form.radius.value = action.payload.location?.radius || 10;
      state.form.coordinates.value = action.payload.location?.coordinates;
      state.form.stationImageId.value = action.payload?.stationImage?._id;
      state.form.stationImageUrl.value = action.payload?.stationImage?.url;
      state.form.isAddressVisible.value =
        action.payload.location?.isAddressVisible;
      state.form.qrText.value = action.payload.location?.code;
      state.selectedQuestions = action.payload.stationQuestions.map(
        (q) => q?._id
      );
      //@ts-ignore
      state.fullQuestions = action.payload.stationQuestions;
      state.form.id.value = action.payload?._id;
      state.isLoading = false;
    },
    getStationError(state) {
      state.isLoading = false;
    },
    createStation(
      state,
      action: PayloadAction<{ station: Station; callback?: () => void }>
    ) {
      state.isLoading = true;
    },
    createStationSuccess(state) {
      state.isLoading = false;
      state.isUnsaved = false;
    },
    createStationError(state) {
      state.isLoading = false;
    },
    updateStation(
      state,
      action: PayloadAction<{ station: Station; callback?: () => void }>
    ) {
      state.isLoading = true;
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateStationSuccess(state, action: PayloadAction<Station>) {
      state.form.name.value = action.payload.name;
      state.form.goToMessage1.value = action.payload.goToMessage1;
      state.form.goToMessage2.value = action.payload.goToMessage2;
      state.form.feedBackOnSuccessfulArrival.value =
        action.payload.feedBackOnSuccessfulArrival;
      state.form.stationInstruction.value = action.payload.stationInstruction;
      state.form.type.value = action.payload.location?.type;
      state.form.address.value = action.payload.location?.address;
      state.form.code.value = action.payload.location?.code;
      state.form.radius.value = action.payload.location?.radius || 10;
      state.form.coordinates.value = action.payload.location?.coordinates;
      state.form.stationImageId.value = action.payload?.stationImage?._id;
      state.form.stationImageUrl.value = action.payload?.stationImage?.url;
      state.form.isAddressVisible.value =
        action.payload.location?.isAddressVisible;
      state.form.id.value = action.payload?._id;
      state.form.qrCode.value = action.payload.location?.code;
      state.form.qrText.value = action.payload.location?.code;
      state.isLoading = false;
      state.isUnsaved = false;
    },
    updateStationError(state) {
      state.isLoading = false;
    },
    deleteStation(state, action: PayloadAction<string>) {
      state.isLoading = true;
      state.stations = state.stations.filter((x) => x._id != action.payload);
    },
    deleteStationSuccess(state) {
      state.isLoading = false;
    },
    deleteStationError(state) {
      state.isLoading = false;
    },
    getQuestions(state, action: PayloadAction<Query>) {
      state.isLoadingQuestions = true;
    },
    getQuestionsSuccess(
      state,
      action: PayloadAction<{ questions: Question[]; pagination: Pagination }>
    ) {
      state.questions =
        action.payload.pagination.page === 1
          ? [
              ...action.payload.questions.filter(
                (x) =>
                  state.fullQuestions.findIndex((y) => y._id == x._id) == -1
              ),
              ...state.fullQuestions,
            ]
          : [...state.questions, ...action.payload.questions];
      state.questionsPagination = action.payload.pagination;
      state.isLoadingQuestions = false;
    },
    getQuestionsError(state) {
      state.isLoadingQuestions = false;
    },
    generateBarcode(state, action: PayloadAction<string>) {},
    setBarcode(state, action: PayloadAction<string>) {
      state.form.qrCode.value = action.payload;
    },
    addToSelectedQuestions(state, action: PayloadAction<string>) {
      state.selectedQuestions.push(action.payload);
    },
    removeFromSelectedQuestions(state, action: PayloadAction<string>) {
      state.selectedQuestions = state.selectedQuestions.filter(
        (q) => q !== action.payload
      );
    },
    setSelectedQuestions(state, action: PayloadAction<string[]>) {
      state.selectedQuestions = action.payload;
    },
    resetForm(state, action: PayloadAction<{ callback?: () => void }>) {
      state.form = initialState.form;
      set(state.form, `qrText.value`, randomString(5));
      state.isUnsaved = false;
      state.selectedQuestions = [];
    },
  },
});

export const {
  reducer: stationsReducer,
  actions,
  name: stationsSlice,
} = formSlice;
