import { Box, IconButton, SxProps, Theme, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import trash from "assets/ic_delete.svg";
import { MediaTypes } from "app/containers/types";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useTranslation } from "react-i18next";
import { ProgressBar, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { selectDirection } from "styles/theme/slice";
import { useSelector } from "react-redux";
interface UploadViewProps {
  type: MediaTypes;
  getRootProps: any;
  acceptedFiles: File[];
  onRemove: () => void;
  containerStyle?: SxProps<Theme>;
  caption?: string;
  error?: string;
  src?: string;
  width?: string;
  height?: string;
}

export default function UploadView(props: UploadViewProps) {
  const {
    getRootProps,
    onRemove,
    acceptedFiles,
    containerStyle,
    caption = "Supports PNG, JPG 320x200px images",
    type,
    src,
    width,
    height,
    error,
  } = props;
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);

  const uploadedImageSrc =
    acceptedFiles?.length > 0 ? URL.createObjectURL(acceptedFiles[0]) : null;

  return (
    <Box
      {...(acceptedFiles?.length > 0 ? {} : { ...getRootProps() })}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        border: "2px dashed #051D59",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
        flexDirection: "column",
        gap: "10px",
        position: "relative",
        ...containerStyle,
      }}
    >
      {acceptedFiles?.length > 0 && (
        <IconButton
          onClick={() => onRemove()}
          sx={{
            position: "absolute",
            top: "16.5px",
            right: "18px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "32px",
            height: "32px",
            borderRadius: "4px",
            zIndex: 3,
          }}
        >
          <img src={trash} alt="upload" />
        </IconButton>
      )}
      {acceptedFiles?.length < 1 && (
        <Typography
          sx={{
            
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "19px",
            letterSpacing: "0px",
            textAlign: "center",
            color: "#69789C",
            flexDirection: "row",
            display: "flex",
            gap: "5px",
          }}
        >
          {t("COMMON.LABELS.UPLOAD_TITLE")}
          <Typography
            sx={{
              
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "19px",
              letterSpacing: "0px",
              textAlign: "center",
              color: "#051D59",
              cursor: "pointer",
            }}
          >
            {t("COMMON.LABELS.CHOOSE_FILE")}
          </Typography>
        </Typography>
      )}
      <Box
        sx={{
          width: width || "390px",
          height: height || "95px",
          borderRadius: "4px",
          border: acceptedFiles?.length > 0 ? "none" : "1px dashed #8890A6",
          backgroundColor: "#FFFFFF",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {acceptedFiles?.length > 0 ? (
          <>
            {(acceptedFiles?.[0].type == "image/png" ||
              acceptedFiles?.[0].type == "image/jpeg" ||
              acceptedFiles?.[0].type == "image/jpg") && (
              <img
                src={uploadedImageSrc as string}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                  objectFit: "contain", 
                }}
              />
            )}
            {acceptedFiles?.[0].type == "video/mp4" && (
              <video
                controls
                style={{
                  height: "100%",
                  borderRadius: "4px",
                  objectFit: "contain", 
                }}
                src={uploadedImageSrc as string}
              />
            )}
            {acceptedFiles?.[0].type == "application/pdf" && (
              <iframe
                src={uploadedImageSrc as string}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                }}
              />
            )}
          </>
        ) : src ? (
          <>
            {type === MediaTypes.IMAGE && (
              <img
                src={src}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                  objectFit: "contain", 
                }}
              />
            )}
            {type === MediaTypes.VIDEO && (
              <video
                controls
                style={{
                  height: "100%",
                  borderRadius: "4px",
                  objectFit: "contain",
                }}
                src={src}
              />
            )}
            {type === MediaTypes.IMAGE_VIDEO &&
              (src?.split(".")[src.split(".").length - 1].toLocaleLowerCase() ==
                "png" ||
              src?.split(".")[src.split(".").length - 1].toLocaleLowerCase() ==
                "jpg" ||
              src?.split(".")[src.split(".").length - 1].toLocaleLowerCase() ==
                "jpeg" ? (
                <img
                  src={src}
                  alt="preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                    objectFit: "contain", 
                  }}
                />
              ) : (
                <video
                  controls
                  style={{
                    height: "100%",
                    borderRadius: "4px",
                    objectFit: "contain",
                  }}
                  src={src}
                />
              ))}
            {type === MediaTypes.PDF && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={src}
                  renderLoader={(percentages: number) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ProgressBar progress={Math.round(percentages)} />
                    </div>
                  )}
                />
              </Worker>
            )}
          </>
        ) : (
          <>
            {type === MediaTypes.VIDEO && (
              <VideocamIcon sx={{ color: "#D7D7E0", fontSize: "100px" }} />
            )}

            {type === MediaTypes.IMAGE && (
              <BrokenImageIcon
                sx={{
                  fontSize: "72px",
                  color: "#D7D7E0",
                }}
              />
            )}
          </>
        )}
      </Box>
      {acceptedFiles?.length < 1 && type !== MediaTypes.PDF && (
        <Typography
          sx={{
            
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "center",
            color: "#69789C",
            flexDirection: "row",
            display: "flex",
            gap: "5px",
          }}
        >
          {caption}
        </Typography>
      )}
      {error && error.length > 0 && (
        <Typography
          sx={{
            
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "center",
            color: "red",
            flexDirection: "row",
            display: "flex",
            gap: "5px",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
}
