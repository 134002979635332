import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  Answer,
  CorelationAnswer,
  FreeTextAnswer,
  MixedLetterAnswer,
  Question,
  QuestionsState,
  SingleMultiSelectAnswer,
} from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";
import toastService from "utils/toast";
import { translate } from "utils/constants";
import { adjustCustomQuestionSingleLength } from "styles/theme/utils";

const initialState = QuestionsState;

export const formSlice = createSlice({
  name: "questionsState",
  initialState,
  reducers: {
    getQuestions: (state, action: PayloadAction<Query>) => {
      state.loading = true;
    },
    getQuestionsSuccess: (
      state,
      action: PayloadAction<{ data: Question[]; pagination: Pagination }>
    ) => {
      state.list =
        action.payload.pagination.page === 1
          ? action.payload.data
          : [...state.list, ...action.payload.data];
      state.pagination = action.payload.pagination;
      state.loading = false;
      state.isUnsaved = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssetsValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}`, action.payload.value);

      state.error = "";
    },
    setCoRelationAnswer: (state, action: PayloadAction<CorelationAnswer[]>) => {
      if (!state.answer.some((x) => x.freeText)) {
        state.answer = action.payload.map((item) => {
          const items: Answer = { corelation: item };
          return items;
        });
      }
      state.error = "";
    },

    setMultiSelectAnswer: (
      state,
      action: PayloadAction<SingleMultiSelectAnswer[]>
    ) => {
      if (!state.answer.some((x) => x.multiSelect)) {
        state.answer = action.payload.map((item,i) => {
          if (state.answer[i]) {
            const items: Answer = state.answer[i];
            items.multiSelect = item;
            return items;
          } else {
            const items: Answer = { multiSelect: item };
            return items;
          }
         
        });
      }

      state.error = "";
    },
    setSingleSelectAnswer: (
      state,
      action: PayloadAction<SingleMultiSelectAnswer[]>
    ) => {
      if (!state.answer.some((x) => x.singleSelect)) {
        state.answer = action.payload.map((item, i) => {
          if (state.answer[i]) {
            const items: Answer = state.answer[i];
            items.singleSelect = item;
            return items;
          } else {
            const items: Answer = { singleSelect: item };
            return items;
          }
        });
      }
      state.error = "";
    },
    setMixedLetterAnswer: (
      state,
      action: PayloadAction<MixedLetterAnswer[]>
    ) => {
      if (!state.answer.some((x) => x.mixedLetter)) {
        state.answer = action.payload.map((item,i) => {
          if (state.answer[i]) {
            const items: Answer = state.answer[i];
            items.mixedLetter = item;
            return items;
          } else {
            const items: Answer = { mixedLetter: item };
            return items;
          }
         
        });
      }
      state.error = "";
    },
    setFreeTextAnswer: (state, action: PayloadAction<FreeTextAnswer[]>) => {
      if (!state.answer.some((x) => x.freeText)) {
        state.answer = action.payload.map((item,i) => {
          if (state.answer[i]) {
            const items: Answer = state.answer[i];
            items.freeText = item;
            return items;
          } else {
            const items: Answer = { freeText: item };
            return items;
          }
        });
      }
      state.error = "";
    },
    setSingleUpdateAnswer: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      for (let index = 0; index < state.answer.length; index++) {
        const element = state.answer[index];
        if (index == action.payload.index) {
          element[action.payload.key] = action.payload.value;
        } else {
          element[action.payload.key] = false;
        }
        state.answer[index] = element;
      }
      const item = state.answer[action.payload.index];
      item[action.payload.key] = action.payload.value;
      state.answer[action.payload.index] = item;
      state.error = "";
    },

    setUpdateAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        type: string;
        key: string;
        value: any;
      }>
    ) => {
      set(
        state,
        `answer[${action.payload.index}].${action.payload.type}.${action.payload.key}`,
        action.payload.value
      );

      state.error = "";
    },

    setUpdateIsCorrectAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        type: string;
        key: string;
        value: any;
      }>
    ) => {
      state.answer.map((x)=>{
        if(x.singleSelect){
          x.singleSelect.isCorrect=false;
        }
        return x
      })
      set(
        state,
        `answer[${action.payload.index}].${action.payload.type}.${action.payload.key}`,
        action.payload.value
      );

      state.error = "";
    },

    setUpdateMixedLetterAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        innerIndex: number;
        key: string;
        value: any;
      }>
    ) => {
      set(
        state,
        `answer[${action.payload.index}].freeText.letters[${action.payload.innerIndex}].${action.payload.key}`,
        action.payload.value
      );
      state.error = "";
    },
    toggleUpdateFreeTextRtlOption: (
      state,
      action: PayloadAction<{
        index: number;
        value: any;
      }>
    ) => {
      set(
        state,
        `answer[${action.payload.index}].freeText.isPlotRTL`,
        action.payload.value
      );
      state.error = "";
    },
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
      if (action.payload.key !== "organizationId") {
        state.isUnsaved = true;
      }
    },
    setFormClearId: (state) => {
      state.form._id = "";
      state.error = "";
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setFormErrors(state, action: PayloadAction<FormError[]>) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
        toastService.error(translate(error.error));
      });
    },
    resetForm: (state, action: PayloadAction<{ callback?: () => void }>) => {
      state.loading = false;
      state.form = initialState.form;
      state.isUnsaved = false;
      state.answer = initialState.answer;
    },
    createQuestion: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.loading = true;
    },
    createQuestionSuccess: (state) => {
      state.loading = false;
      state.isUnsaved = false;
    },
    createQuestionError: (state) => {
      state.loading = false;
    },
    updateQuestion: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.loading = true;
    },
    updateQuestionSuccess: (state) => {
      state.loading = false;
      state.isUnsaved = false;
    },
    updateQuestionError: (state) => {
      state.loading = false;
    },
    deleteQuestion: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.list = state.list.filter((x) => x._id != action.payload);
    },
    deleteQuestionSuccess: (state) => {
      state.loading = false;
    },
    deleteQuestionError: (state) => {
      state.loading = false;
    },
    getQuestionError: (state) => {
      state.loading = false;
    },
    getQuestionSuccess: (state, action: PayloadAction<Question>) => {
      state.form._id = action.payload._id;
      state.form.body.value = action.payload.body;
      state.form.name.value = action.payload.name;
      state.form.rightAnswerComment.value = action.payload.rightAnswerComment;
      state.form.score.value = action.payload.score || 0;
      state.form.link.value = action.payload.link;
      state.form.linkLabel.value = action.payload.linkLabel;
      state.form.showLink.value = action.payload.showLink;
      state.form.organizationId.value = action.payload.organizationId;
      state.form.type.value = action.payload.type;
      state.form.asset = action.payload.asset;
      state.form.isCustomQuestion = action.payload.isCustomQuestion;
      state.answer = action.payload.answers.map((item: any) => {
        let items: Answer = {};
        if (state.form.type.value == "single_select") {
          items = { singleSelect: item };
        }
        if (state.form.type.value == "multi_select") {
          items = { multiSelect: item };
        }
        if (state.form.type.value == "mixed_letter") {
          items = { mixedLetter: item };
        }
        if (state.form.type.value == "correlation") {
          items = { corelation: item };
        }
        if (state.form.type.value == "free_text") {
          items = { freeText: item };
        }
        return items;
      });
      if(state.form.isCustomQuestion ){
        if ( "single_select" == state.form.type.value  ) {
          state.answer = adjustCustomQuestionSingleLength(state.answer)
        }
      }
      state.isUnsaved = false;
      state.loading = false;
    },

    getQuestion: (
      state,
      action: PayloadAction<{ id: string; duplicate?: boolean }>
    ) => {
      state.loading = true;
    },
  },
});

export const {
  reducer: questionsReducer,
  actions,
  name: questionsSlice,
} = formSlice;
