import { Box, Typography } from "@mui/material";
import CustomTable, { StyledCell } from "app/components/Table";
import { TableHeaderColumnType } from "app/components/Table/types";
import CustomTableRow from "app/components/TableRow";
import { selectOrderedList } from "app/containers/Results/redux/selector";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatHumanizedDuration, formatTableTime } from "styles/theme/utils";
import {LineButton} from "../LineButton";
import StationModal from "../StationModal";
import {actions} from '../../../../redux/slice'
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useSearchParams } from "react-router-dom";
import { OrderDirections } from "app/containers/types";
const TableHeaderDefinition = {
  NUM: {
    label: "NUM",
    format: (value) => value,
    maxWidth: 80,
    minWidth: 80,
    sortable: false,
  },
  GROUP_NAME: {
    id: 'groupName',
    label: "GROUP_NAME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  START_TIME: {
    id: 'startTime',
    label: "START_TIME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  END_TIME: {
    id: 'endTime',
    label: "END_TIME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  SCORE: {
    id: 'score',
    label: "SCORE",
    format: (value) => value,
    maxWidth: 70,
    minWidth: 70,
    sortable: true,
  },
  CORRECT_QUESTIONS: {
    id: 'correctQuestions',
    label: "CORRECT_QUESTIONS",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
  },
  MISTAKES: {
    id: 'incorrectAttempts',
    label: "MISTAKES",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  LOCATION_MISTAKES: {
    id: 'locationMistakes',
    label: "LOCATION_MISTAKES",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
  },
  STATIONS_DONE: {
    label: "STATIONS_DONE",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },
  QUESTIONS_DONE: {
    label: "QUESTIONS_DONE",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },

  TOTAL_TIME: {
    id: 'totalTime',
    label: "TOTAL_TIME",
    format: (value) => value,
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },

  STATUS: {
    id: 'status',
    label: "STATUS",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  CURRENT_STATION: {
    id: 'currentStation',
    label: "CURRENT_STATION",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },
  STATIONS: {
    id: "actions",
    label: "STATIONS",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: false,
  },

};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

export default function Table() {
  const list = useSelector(selectOrderedList);
  const searchParams = useSearchParams();
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;
  
  const dispatch =  useDispatch()
  const {t} = useTranslation()

  const handleCurrentResult = (id?: string) => {
    dispatch(actions.setCurrentResult(id))
  }


  useEffect(()=>{
    if(orderBy && orderDirection) {
      dispatch(actions.onSort({name: orderBy, direction: orderDirection}))
    }
  
  },[orderBy, orderDirection])

  

  const { height } = useWindowDimensions();
  if(list.length == 0) {
    return (
      <Box
        sx={{
          paddingX: "12px",
          marginTop: "32px",
          marginBottom: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          height: height - 420,
          overflowY: 'scroll',
        }}
      > 
        <Typography noWrap style={{textAlign: 'center'}}> {t('RESULTS.LABELS.NO_DATA_YET')}</Typography>
      </Box>
        )

  }
  return (
    <Box
      sx={{
        paddingX: "12px",
        marginTop: "32px",
        overflowX: 'scroll',
        marginBottom: '10px',
      }}
    >
      <CustomTable headers={TableHeaders} isColoredHeader >
        {list.map((row, index) => (
          <CustomTableRow key={row._id}>
            <StyledCell
              minWidth={TableHeaderDefinition.NUM.minWidth}
              maxWidth={TableHeaderDefinition.NUM.maxWidth}
            >
              {index + 1}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GROUP_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GROUP_NAME.maxWidth}
            >
              {row.groupName}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.START_TIME.minWidth}
              maxWidth={TableHeaderDefinition.START_TIME.maxWidth}
            >
              {formatTableTime(row.startTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.END_TIME.minWidth}
              maxWidth={TableHeaderDefinition.END_TIME.maxWidth}
            >
              {formatTableTime(row.endTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.SCORE.minWidth}
              maxWidth={TableHeaderDefinition.SCORE.maxWidth}
            >
              {row.score || 0}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CORRECT_QUESTIONS.minWidth}
              maxWidth={TableHeaderDefinition.CORRECT_QUESTIONS.maxWidth}
            >
              {row.correctQuestions || 0}
            </StyledCell>
            
            <StyledCell
              minWidth={TableHeaderDefinition.MISTAKES.minWidth}
              maxWidth={TableHeaderDefinition.MISTAKES.maxWidth}
            >
              {row.incorrectAttempts}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.LOCATION_MISTAKES.minWidth}
              maxWidth={TableHeaderDefinition.LOCATION_MISTAKES.maxWidth}
            >
              {row.locationMistakes}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATIONS_DONE.minWidth}
              maxWidth={TableHeaderDefinition.STATIONS_DONE.maxWidth}
            >
                {t('RESULTS.MIN_OF_MAX', {min: row.completedStations, max: row.totalStations})}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTIONS_DONE.minWidth}
              maxWidth={TableHeaderDefinition.QUESTIONS_DONE.maxWidth}
            >
                {t('RESULTS.MIN_OF_MAX', {min: row.completedQuestions, max: row.totalQuestions})}
            </StyledCell>

            
            <StyledCell
              minWidth={TableHeaderDefinition.TOTAL_TIME.minWidth}
              maxWidth={TableHeaderDefinition.TOTAL_TIME.maxWidth}
            >
              {formatHumanizedDuration(row.startTime, row.endTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATUS.minWidth}
              maxWidth={TableHeaderDefinition.STATUS.maxWidth}
            >
              {row.currentStation?._id ? t('RESULTS.STATUS.IN_PROGRESS') : t('RESULTS.STATUS.DONE')}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CURRENT_STATION.minWidth}
              maxWidth={TableHeaderDefinition.CURRENT_STATION.maxWidth}
            >
              {row.currentStation?.name}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATIONS.minWidth}
              maxWidth={TableHeaderDefinition.STATIONS.maxWidth}
              style={{padding: '6px'}}
            >
              <LineButton onClick={()=> handleCurrentResult(row._id)}>
                 {t('RESULTS.LABELS.SEE_ALL')}
              </LineButton>
             
            </StyledCell>
          </CustomTableRow>
        ))}

      </CustomTable>
      <StationModal  onClose={() => handleCurrentResult(undefined)} />
    </Box>
  );
}
